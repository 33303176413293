<template>
  <div
    style="max-height: 580px; overflow-y: auto"
  >
    <v-toolbar
      dark
      dense
      flat
      color="primary"
    >
      <v-spacer />
      <v-toolbar-title>
        Opérations successives
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-list
      two-line
    >
      <template v-for="operation in internalValue.orderOperations">
        <v-list-item :key="operation._id">
          <v-list-item-content>
            <v-list-item-title class="text-body-1">
              {{ operation.operation.operationType.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              Rebut: {{ operation.stats.rejected }} Quarantaine: {{ operation.stats.suspended }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text class="text-subtitle-1">
              {{ operation.todo }}/{{ operation.done }}
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="operation._id + 'divider'" class="mx-4" />
      </template>
    </v-list>
    <v-toolbar
      dark
      dense
      flat
      color="primary"
    >
      <v-spacer />
      <v-toolbar-title>
        Opérations parrallèles
      </v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-list
      two-line
    >
      <template v-for="operation in internalValue.unorderOperations">
        <v-list-item :key="operation._id">
          <v-list-item-content>
            <v-list-item-title class="text-body-1">
              {{ operation.operation.operationType.name }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-list-item-action-text class="text-subtitle-1">
              -/{{ operation.done }}
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="operation._id + 'divider'" class="mx-4" />
      </template>
    </v-list>
  </div>
</template>

<script>
import Helpers from 'src/utils/helpers'

export default {
  name: 'BatchDetails',
  components: { },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
  }),
  computed: {},
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
      },
    },
  },
  methods: {},
}
</script>
