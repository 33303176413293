<template>
  <ag-modal-container
    :title="value ? `Detail de l'OF ${value.number}`: ''"
    width="600px"
    no-content-padding
    @close="back"
  >
    <v-row v-if="loading" justify="center" class="ma-2">
      <v-progress-circular
        style="text-center"
        :size="80"
        width="8"
        indeterminate
        color="primary"
      />
    </v-row>
    <v-tabs
      v-if="value"
      v-model="tab"
      background-color="primary"
      color="primary"
      :slider-size="5"
      slider-color="accent"
      grow
    >
      <v-tab
        v-text="'Opérations'"
      />
      <v-tab
        v-text="'Pièces'"
      />
    </v-tabs>
    <v-tabs-items v-if="value" v-model="tab">
      <v-tab-item>
        <batch-details
          v-model="value"
        />
      </v-tab-item>
      <v-tab-item eager>
        <part-list v-model="value" />
      </v-tab-item>
    </v-tabs-items>
  </ag-modal-container>
</template>

<script>
import Repositories from 'src/repositories'
import { mapActions } from 'vuex'
import AgModalContainer from 'src/components/common/AgModalContainer'
import BatchDetails from '../components/BatchDetails'
import PartList from '../components/PartList'

export default {
  name: 'BatchDetailsContainer',
  components: {
    AgModalContainer,
    BatchDetails,
    PartList,
  },
  props: {
    batchId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isValid: false,
    loading: false,
    value: null,
    tab: null,
  }),
  watch: {
    $route: {
      immediate: true,
      async handler () {
        try {
          this.loading = true
          const res = await Repositories.batch.getOneWithStats(this.batchId)
          this.value = res.data
        }
        catch (error) {
          this.showErrorNotification(Repositories.parseErrorMessage(error))
        }
        finally {
          this.loading = false
        }
      },
    },
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    back () {
      this.$router.back()
    },
  },
}
</script>
