<template>
  <div>
    <v-text-field
      v-model="filter"
      label="Recherche"
      class="mx-8 my-4"
      outlined
      dense
      hide-details
      prepend-icon="search"
    />
    <v-divider />
    <v-list>
      <v-virtual-scroll
        :items="filterPartList"
        height="500"
        item-height="48"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item._id" :style="`background-color: ${getItemClass(item)}`">
            <v-list-item-content>
              <v-list-item-title class="text-body-1">
                {{ item.serialNumber }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text
                v-if="item.currentOperationType && (item.rejected || item.suspended)"
                class="text-subtitle-1"
              >
                {{ item.currentOperationType.name }}
                ({{ item.rejected ? 'Rebuté opé précédente' : 'Quarantaine opé précédente' }})
              </v-list-item-action-text>
              <v-list-item-action-text
                v-else-if="item.currentOperationType"
                class="text-subtitle-1"
              >
                {{ item.currentOperationType.name }}
              </v-list-item-action-text>
              <v-list-item-action-text v-else class="text-subtitle-1">
                Finis
              </v-list-item-action-text>
            </v-list-item-action>
          </v-list-item>
          <v-divider />
        </template>
      </v-virtual-scroll>
    </v-list>
  </div>
</template>

<script>
import Helpers from 'src/utils/helpers'

export default {
  name: 'PartList',
  components: { },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    internalValue: null,
    filter: '',
  }),
  computed: {
    filterPartList () {
      if (!this.filter) return this.internalValue.parts
      return this.internalValue.parts
        .filter(part => part.serialNumber.toLowerCase().includes(this.filter))
    },
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.internalValue = Helpers.clone(value)
      },
    },
  },
  methods: {
    getItemClass (item) {
      if (item.destroyed) return '#b7b7b7'
      if (item.rejected) return '#ffaeae'
      if (item.retouched) return '#aed4ff'
      if (item.exception) return '#fff7ae'
      return ''
    },
  },
}
</script>
